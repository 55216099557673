import React from 'react'
import { Link } from 'gatsby'
import '../styles/footer.sass'

import instagram from '../img/social/instagram.svg'
import twitter from '../img/social/twitter.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <>
        <footer className="footer-container">
          <div className="has-text-centered">
            <ul>
              <li>
                <a title="twitter" target="_blank" href="https://twitter.com/phoebewynne">
                  <img
                    className="fas fa-lg"
                    src={twitter}
                    alt="Twitter"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </li>

              <li>
                <a
                  title="instagram"
                  target="_blank"
                  href="https://instagram.com/phoebewynnewrites"
                >
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: '1em', height: '1em' }}
                  />
                </a>
              </li>
            </ul>

            <p>© Copyright Phoebe Wynne 2021</p>

            <p>
              <a href="mailto:hello@hannahblair.co.uk" target="_blank">
                Website built by Hannah Blair
              </a>
            </p>
          </div>
        </footer>
      </>
    )
  }
}

export default Footer
