import React from 'react'
import '../styles/buynow.sass'
import PropTypes from 'prop-types'

export const BuyNow = ({ heading, buyLinkUK, buyTextUK }) => {
  return (
    <div className="container buy">
      <h1 className="text-center">BUY</h1>
      <p>{heading}</p>
      <div className="button-container">
        <a
          target="_blank"
          href={buyLinkUK}
          className="buy-option"
        >
          {buyTextUK}
        </a>
      </div>
    </div>
  )
}

BuyNow.propTypes = {
  heading: PropTypes.string,
  buyLinkUK: PropTypes.string,
  buyTextUK: PropTypes.string,
}

export default BuyNow
