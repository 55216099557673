import React from 'react'
import Navbar from '../components/Navbar'
import MadamBook from '../img/madambook.png'
import MadamBookUS from '../img/coverus.jpeg'
import Layout from '../components/Layout'
import '../styles/books.sass'
import Squiggle from '../img/squiggly2.png'
import BuySection from '../components/BuyNow'
import Signature from '../img/signature.png'
import Footer from '../components/Footer'
import MiniMenu from '../components/MiniMenu'
import MadamLetter from '../img/MadamAuthorLetter.pdf'
import DiscussionQs from '../img/discussionQs.pdf'

export const MadamPageTemplate = () => (
  <>
    <div>
      <Navbar showSub={false} showMenu={true} bold />
      <MiniMenu />
      <div className="container books">
        <div className="columns">
          <div className="column">
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <img className="madam-book-img" src={MadamBook}></img>
              <img className="madam-book-img" src={MadamBookUS}></img>
            </div>
            <h1 id="summary" className="text-center">Madam</h1>
            <p>
              <div style={{
                fontWeight: 'bold', fontSize: '19px',
              }}>
                Perfect for fans of Margaret Atwood and Madeline Miller, Madam is
                a darkly feminist tale with an electrifying cast of heroines you
                won’t soon forget. <br /></div>

              <br /> For 150 years, high above rocky
              Scottish cliffs, Caldonbrae Hall has sat untouched, a beacon of
              excellence in an old ancestral castle. A boarding school for
              girls, it promises that the young women lucky enough to be
              admitted will emerge “resilient and ready to serve society.” Into
              its illustrious midst steps Rose Christie: a 26-year-old Classics
              teacher, Caldonbrae’s new head of the department, and the first
              hire for the school in over a decade. At first, Rose is
              overwhelmed to be invited into this institution, whose prestige is
              unrivalled. But she quickly discovers that behind the school’s
              elitist veneer lies an impenetrable, starkly traditional culture
              that she struggles to reconcile with her modernist beliefs – not
              to mention her commitment to educating “girls for the future.”{' '}
              <br /> <br /> It doesn’t take long for Rose to suspect that
              there’s more to the secret circumstances surrounding the abrupt
              departure of her predecessor--a woman whose ghost lingers
              everywhere--than anyone is willing to let on. In her search for
              this mysterious former teacher, Rose instead uncovers the darkness
              that beats at the heart of Caldonbrae, forcing her to confront the
              true extent of the school’s nefarious purpose, and her own role in
              perpetuating it. A darkly feminist tale pitched against a haunting
              backdrop, and populated by an electrifying cast of
              heroines, Madam will keep readers engrossed until the breathtaking
              conclusion.
              <br />
              <br />
              It doesn’t take long for Rose to suspect that there’s more to the
              secret circumstances surrounding the abrupt departure of her
              predecessor--a woman whose ghost lingers everywhere--than anyone
              is willing to let on. In her search for this mysterious former
              teacher, Rose instead uncovers the darkness that beats at the
              heart of Caldonbrae, forcing her to confront the true extent of
              the school’s nefarious purpose, and her own role in perpetuating
              it. A darkly feminist tale pitched against a haunting backdrop,
              and populated by an electrifying cast of heroines, Madam will keep
              readers engrossed until the breathtaking conclusion.
            </p>
          </div>
        </div>

        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>

        <div id="buy">
          <BuySection
            heading={"Madam is currently available everywhere books are sold."}
            buyLinkUK={"https://linktr.ee/PhoebeWynne"}
            buyTextUK={"Buy Madam"}
          />
        </div>

        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>
        <h1 className="text-center" id="reviews">reviews</h1>
        <div className="quote-container">
          <div className="quote">
            <div className="quote-content">
              Imagine if Donna Tartt and Margaret Atwood got together to write a
              creepy, suspenseful novel about a school for young women in the
              Scottish Highlands. The result is Madam, a book I couldn't for the
              life of me put down. Brooding and unsettling, Wynne paints a
              gorgeous picture that only serves to camouflage the dark secrets
              she's hidden within.
            </div>
            <div className="quote-name">Sara Collins</div>
            <div className="quote-bio">
              Author of The Confessions of Frannie Langton
            </div>
          </div>
          <div className="quote">
            <div className="quote-content">
              The simmering menace and mystery kept me absolutely gripped. It
              gave me the same feeling as when I read The Secret History and put
              me in mind of The Furies. I loved the clever interweaving stories
              of the classical women of ancient myth and history with the
              tantalizing reveal of the horrifying truth behind the impressive
              facade of the grand boarding school. This was a smouldering slow
              burn of a novel that I could not put down.
            </div>
            <div className="quote-name">Jennifer Saint</div>
            <div className="quote-bio">Author of Ariadne</div>
          </div>
          <div className="quote">
            <div className="quote-content">
              Rebecca meets The Secret History: gloriously dark, gloriously
              gothic.
            </div>
            <div className="quote-name">Chandler Bake</div>
            <div className="quote-bio">
              New York Times bestselling author of Whisper Network
            </div>
          </div>

          <div className="quote">
            <div className="quote-content">
              Full of fire and light, written with a passionate
              intensity...Madam is a book that shimmers. It’s an extraordinary
              achievement‘
            </div>
            <div className="quote-name">Now and Fen blog</div>
          </div>

          <div className="quote">
            <div className="quote-content">
              I ripped through it and thoroughly enjoyed the ride. It had real
              echoes of Jean Brodie and a flavour of one of my favourite films
              Heathers, too! A highly entertaining and atmospheric read.
            </div>
            <div className="quote-name">Kate Sawyer</div>
            <div className="quote-bio">Author of The Stranding</div>
          </div>

          <div className="quote">
            <div className="quote-content">
              A thrilling debut, reminiscent of Du Maurier. The narrative,
              written in precise prose with beautifully crafted characters,
              barrels along at pace, leaving us breathless at each twist and
              turn. A truly wonderful read.
            </div>
            <div className="quote-name">Nydia Hetherington</div>
            <div className="quote-bio">Author of A Girl Made Of Air</div>
          </div>
        </div>
        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>
        <div className="letter-container">
          <h1 className="text-center" id="letter">a letter to readers</h1>

          <a href={MadamLetter} target="_blank">
            <h3 className="text-center download-link">Download →</h3>
          </a>
          <p>
            Dear Reader, <br />
            <br />
            They say write what you know, and Madam is indeed drawn from my
            personal experience, both as a Classics teacher, and as a girl in a
            British boarding school. There is truth to the traditions, societal
            expectations, traumas, and struggles depicted within the pages of
            Madam, and some of it may surprise you. Caldonbrae Hall is certainly
            no Hogwarts. Institutions are dangerous places, and no genre knows
            this better than the gothic. I’ve always been compelled by the
            menacing ideas of the looming building, the male oppressor, the
            subjugated female, and her desire for escape. My greatest gothic
            writing ‘shero’ is Daphne du Maurier, who spun her fascinating webs
            so eerily and uncomfortably that they upset her contemporaries and
            stood the test of time, just as Emily and Charlotte Bronte managed
            before her. Just like Jane Eyre, my heroine, Rose, learns that she
            must connect herself to something substantial to exist. Rose clings
            to her education and to the academic institution she knows well -
            but the one she’s thrust into is rotten, as are its age-old customs.
            The girls enjoy these customs, as they know nothing else, bar three
            young students, who are intrigued by their new teacher’s tales of
            classical literature. Having absorbed the lessons of the ancient
            heroines, they rise up, discovering their own version of compassion,
            and some version of autonomy. Madam is at its heart a story about
            rebellion, and how we must break free of oppression to find true
            connections and live as liberated women. Madam’s catastrophic ending
            came to me first, its setting and imagery so powerful that I was
            forced to find the story’s beginning from there. Greek tragedy was
            my great friend and influence, with its themes of moral dilemma,
            suffering, and catharsis. I stormed through Madam as if it were a
            story possessed, peppered with bright characters with loud voices
            that crowded around my mind, and crowd around it still. Heroines
            both real and literary pushed through the pages to express
            themselves and rid themselves of their constraints. I hope you find
            their freedom as exhilarating as I did. <br />
            <br />
            <img className="madam-book-sig" src={Signature}></img>
            <br />
          </p>

        </div>

        <div className="section section-container">
          <img className="squiggle" src={Squiggle}></img>
        </div>

        <div className="questions-container container-book2">


          <h1 id="book-club" className="text-center">Book Club Discussions</h1>
          <a href={DiscussionQs} target="_blank">
            <h3 className="text-center download-link">Download →</h3>
          </a>
          <p>
            <ol>
              <li> How did the opening lines of the prologue set the tone for the rest of the novel? When you finally read the moment it depicted,
                did the impact of that scene change for you? </li>
              <li> The setting for Madam and Caldonbrae Hall are rocky Scottish cliffs. How does this isolated coast serve the narrative? Do you
                think the book would be the same or different without it? Why or why not?<br /></li>
              <li> Discuss the structure of the novel. The sections are broken down by term, the interstitials of Greek and Roman mythology. How
                does this structure work to serve and progress the narrative?<br /></li>
              <li> On page 30, Nessa asks Rose, “Then why have they sent you to teach us?” After finishing the novel, does that question carry a
                different weight to you than when you first read it?<br /></li>
              <li> Rose teaches her students Latin through the translation of Greek and Roman stories about empowered women. Though these stories
                are usually tragedies, the Fourths take much interest in them. Why do you think these particular stories in mythology resonate with
                the young women, even though they typically result in the heroine’s death?<br /></li>
              <li> Rose teaches her students about Daphne, who wished to remain a virgin, young and free, only to be turned into a rooted tree and
                touched by Apollo whenever he chose. In what ways can this mythology be an allusion to the experiences of the young women at Caldonbrae Hall?<br /></li>
              <li> In continuing with question 6, Rose teaches her students about Antigone, who, as a young woman, broke the patriarchal rules of her
                uncle to fight for what she believed in and won. How is this mythology an allusion to the actions of Freddie, Nessa, and Daisy?<br /></li>
              <li> Multiple women are living at Caldonbrae Hall during different phases of their lives. Characters such as Bethany, Freddie, Clarissa,
                Frances, and Vivien accept the practices of the institution and the dominance of the patriarchy. Why do you think these specific characters,
                in all of their differences, are so central to the novel? <br /></li>
              <br />
            </ol>
          </p>
        </div>

      </div>
    </div>
    <Footer />
  </>
)

const MadamPage = () => {
  return (
    <Layout>
      <MadamPageTemplate />
    </Layout>
  )
}

export default MadamPage
