import React from 'react'
import { Link } from 'gatsby'
import '../styles/subnavbar.sass'

const MiniMenu = ({ hideBookClubGuide }) => {
  return (
    <nav
      className="subnavbar-hero minimenu-hero"
      role="navigation"
      aria-label="main-navigation"
    >
      <div
        className='mininav-hero'
      >
        <Link className="subnav-item" to="#buy">
          BUY
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#summary">
          SUMMARY
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#reviews">
          REVIEWS
        </Link>
        <div className="subseparator">•</div>
        <Link className="subnav-item" to="#letter">
          AUTHOR LETTER
        </Link>
        {!hideBookClubGuide &&
          <>
            <div className="subseparator">•</div>
            <Link className="subnav-item" to="#book-club">
              BOOK CLUB GUIDE
            </Link>
          </>}
      </div>
    </nav>
  )
}

export default MiniMenu
